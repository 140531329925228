<template>
    <section class="invoice-add-wrapper">
    <spinner-border v-if="loading" />
    
    <b-row class="card" >
                <b-col cols="12">
                  <b-card-text class="mb-12">
                  <br />
                    <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Schedule Date</label>
            <b-form-datepicker v-model="selectedDate" @input="loadData()"
            :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
            class="col-xl-3 col-lg-3 col-md-3" ></b-form-datepicker> 
            </div>
            </b-form-group>
                    <br />
                    <br />
                    <div v-if="scheduleData != null">
                    <span class="font-weight-bold">Note: </span>
                    <b-form-textarea v-model="scheduleData.schedule_remark" />
                     <div class="d-flex mt-2 justify-content-end" style="margin-bottom:20px;">
                        <b-button
                          variant="primary"
                          class="mr-2"
                          @click="doSave()">
                          Save
                        </b-button>
                        <b-button
                          variant="primary"
                          class="mr-2"
                          @click="showPrintModal()">
                          Print
                        </b-button>
                     
                    <br /><br />
                  </div> 
                    </div>
                  </b-card-text>
                     
                </b-col>
              </b-row>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="scheduleData === undefined"
    >
      
      <div class="alert-body">
        No schedule found.
      </div>
    </b-alert>
    
    <b-row
      v-if="scheduleData">
    <b-col cols="12" class="d-flex justify-content-between">
      
      <div class="row">
  
    <div v-for="(item, index) in scheduleJobData" :class="(index == 'Z_Unassigned Job')? 'col-12' : 'col-sm-3 col-md-3 col-lg-2 col-xl-2'" v-bind:key="index" style="padding:10px;">
      <h3 :style="(getVehicleStatus(index) != 'Active' && getVehicleStatus(index) != '')?'color:red':''" >{{index.replace("Z_","")}} 
        <span v-if="getVehicleStatus(index) != 'Active' && getVehicleStatus(index) != ''">({{ getVehicleStatus(index) }})</span></h3>
      <draggable  :move="checkMove"
      class="list-group" style="background:#5f5f5f;min-height:50px;" :list="item" group="people" @change="log" :id="index">
        <div
          class="list-group-item"
          v-for="(subitem, subindex) in item"
          :key="subitem.jod_no" :id="subitem.job_id"
          v-if="subitem.job_address != null"
        >
        <table cellpadding="5" style="width:100%;">
          <tr>
            <td><b>Address:</b><br />{{subitem.job_address}}</td>
          </tr> 
          <tr>
            <td>
              <b>Remark</b><br />
              <span v-if="subitem.schedule_job_remark != null" v-html="subitem.schedule_job_remark.replace(/(?:\r\n|\r|\n)/g, '<br />')"></span>
            </td>
          </tr>
          <tr>
            <td>
              <b>Status</b><br />
              <span v-if="subitem.job_status != 'Active'">
                <b style="color:red;">{{ subitem.job_status }}</b>
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <a href="#" style="float:right;" @click="showModal(subitem)">Edit</a>
            </td>
          </tr>
        </table>
        </div>
      </draggable>
    </div> 
      </div>
</b-col>
    </b-row>

    <b-modal size="xl"
      v-model="printModal"
      hide-footer
      title="Print">
      <div class="d-block ">
        <v-select  
                label="vehicle_plat_no"
                :options="vehicleData"
                @input="printListByCar"
                />
      </div> 
      <br /><br />
      <div class="d-block " >
        <div v-for="(item, index) in scheduleJobData" class="col-12" v-bind:key="index">
          <div  id="printMe" v-if="index == printVehiclePlatNo" >
            <h3> {{index}}</h3>
          <table class="table responsive" border="1" >
            <tr>
              <th>No</th>
              <th>Workers</th>
              <th>Tools</th>
              <th>Materials</th>
              <th>Job Site</th>
              <th>Contact</th>
            </tr>
            <tr v-for="(subitem, subindex) in item" :key="subitem.jod_no" :id="subitem.job_id" v-if="subitem.job_id != null">
              <td>
                {{subindex+1}}
              </td>
              <td>
                <span v-for="worker in subitem.workers">
                  {{worker.user_name}},
                </span>
              </td>
              <td>
                <span v-for="tool in subitem.tools">
                  {{tool.tool_name}} x {{tool.tool_quantity}},
                </span>
              </td>
              <td>
                <span v-for="material in subitem.materials">
                  {{material.material_name}} x {{material.material_quantity}},
                </span>
              </td>
              <td>
                {{subitem.job_address}}<br /><br />
                <b>Remark:</b><br />
                <span v-if="subitem.schedule_job_remark != null" v-html="subitem.schedule_job_remark.replace(/(?:\r\n|\r|\n)/g, '<br />')"></span>
              </td>
              <td>
                {{subitem.job_customer_contact_name}} ({{subitem.job_customer_contact_no}})
                

              </td>
            </tr>
          </table>
        </div>
        </div>
        <button class="btn mr-2 btn-primary"  v-print="'#printMe'">Print</button>
      </div>
      </b-modal>

    <b-modal
      v-model="myModal"
      hide-footer
      title="Edit">
      <div class="d-block ">
        <table cellpadding="5" style="width:100%;">
          <tr>
            <td><b>Address:</b><br />{{editData.job_address}}</td>
          </tr>
          <tr>
            <td><b>Customer:</b><br />{{editData.job_customer_name}} ({{editData.job_customer_code}})</td>
          </tr>
          <tr>
            <td><b>Job No:</b><br />{{editData.job_no}}</td>
          </tr>
          <tr>
            <td>
              <table>
            <tr><td colspan="3"><b>Tools:</b></td></tr>
            <tr>  
                  <td>
                    <v-select
                      label="tool_name"
                      :options="toolData"
                      v-model="new_tool"
                    />
                  </td>
                  <td>
                        <b-form-input type="number" step="0.1" v-model="new_tool_quantity" ></b-form-input>
                  </td>
                  <td><a href="#" @click="addToolItem">Add</a></td>
                  </tr>
                
            </table>
              <table class="table">
                  <tr v-if="editTools != null && editTools.length > 0">
                    <td>Name</td>
                    <td>Quantity</td>
                    <td>Action (<a href="#"  @click="removeAllToolItem()">Clear</a>)</td>
                  </tr>
                  <tr v-for="tool in editTools">
                    <td>{{tool.tool_name}}</td>
                    <td>{{tool.tool_quantity}}</td>
                    <td><a href="#" @click="removeToolItem(tool)">Del</a></td>
                  </tr>
                </table> 
            </td>
          </tr>
          <tr>
            <td>
            <table>
            <tr><td colspan="3"><b>Materials:</b></td></tr>
            <tr>  
                  <td>
                    <v-select
                      label="material_name"
                      :options="materialData"
                      v-model="new_material"
                    />
                  </td>
                  <td>
                        <b-form-input type="number" step="0.1" v-model="new_material_quantity" ></b-form-input>
                  </td>
                  <td><a href="#" @click="addMaterialItem">Add</a></td>
                  </tr>
                
            </table>
              <table class="table">
                  <tr v-if="editMaterials != null && editMaterials.length > 0">
                    <td>Name</td>
                    <td>Quantity</td>
                    <td>Action (<a href="#"  @click="removeAllMaterialItem()">Clear</a>)</td>
                  </tr>
                  <tr v-for="material in editMaterials">
                    <td>{{material.material_name}}</td>
                    <td>{{material.material_quantity}}</td>
                    <td><a href="#" @click="removeMaterialItem(material)">Del</a></td>
                  </tr>
                </table>
            </td>
          </tr>
          <tr>
            <td>
              <b>Worker:</b> 
              <v-select
                v-model="editWorkers"
                multiple
                label="user_name"
                :options="workerData"
                @input="doUpdateWorkers"
              />
            </td>
          </tr>
          <tr>
            <td><b>Pending Tasks:</b><br />
              <ul>
              <li v-for="editTask in editTasks" @click="addToRemark(editTask.job_task_description)"> 
                {{editTask.job_task_description}}
              </li>
            </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b>Remark</b><br />
              <b-form-textarea
                id="remark"
                v-model="editRemarks"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </td>
          </tr>
          <tr>
            <td colspan="3">
            <button class="btn mr-2 btn-primary" @click="saveScheduleJob(editData)">Save</button>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </section>
  <!-- modal -->
</template>
<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted, reactive, onMounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, BAlert, BLink, VBToggle,
BTabs, BTab, BModal, BFormDatepicker } from 'bootstrap-vue'
import scheduleStoreModule from '../scheduleStoreModule'
import jobStoreModule from '../../job/jobStoreModule'
import materialStoreModule from '../../material/materialStoreModule'
import toolStoreModule from '../../tool/toolStoreModule'
import vehicleStoreModule from '../../vehicle/vehicleStoreModule'
import userStoreModule from '../../user/userStoreModule'
import print from 'vue-print-nb'

import draggable from "vuedraggable"
import vSelect from 'vue-select'
import SpinnerBorder from '../../../components/spinner/SpinnerBorder.vue'
export default {
  directives: {
    print   
  },
  components: {
    vSelect,
    draggable,
    SpinnerBorder,
    BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, BAlert, BLink, VBToggle,BModal,
    BTabs, BTab, BFormDatepicker,
    Logo,
  }, 
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names 
    'scheduleData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: { 
    
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
    checkMove: function(evt){
    },
    log: function(evt) {
       
    }
  },
  setup() {
    const SCHEDULE_APP_STORE_MODULE_NAME = 'app-schedule'
    const JOB_APP_STORE_MODULE_NAME = 'app-job'
    const MATERIAL_APP_STORE_MODULE_NAME = 'app-material'
    const TOOL_APP_STORE_MODULE_NAME = 'app-tool'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const VEHICLE_APP_STORE_MODULE_NAME = 'app-vehicle'

    // Register module
    if (!store.hasModule(SCHEDULE_APP_STORE_MODULE_NAME)) store.registerModule(SCHEDULE_APP_STORE_MODULE_NAME, scheduleStoreModule)
    if (!store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.registerModule(JOB_APP_STORE_MODULE_NAME, jobStoreModule)
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.registerModule(MATERIAL_APP_STORE_MODULE_NAME, materialStoreModule)
    if (!store.hasModule(TOOL_APP_STORE_MODULE_NAME)) store.registerModule(TOOL_APP_STORE_MODULE_NAME, toolStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.registerModule(VEHICLE_APP_STORE_MODULE_NAME, vehicleStoreModule)
    
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCHEDULE_APP_STORE_MODULE_NAME)) store.unregisterModule(SCHEDULE_APP_STORE_MODULE_NAME)
      if (store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.unregisterModule(JOB_APP_STORE_MODULE_NAME)
      if (store.hasModule(MATERIAL_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIAL_APP_STORE_MODULE_NAME)
      if (store.hasModule(TOOL_APP_STORE_MODULE_NAME)) store.unregisterModule(TOOL_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLE_APP_STORE_MODULE_NAME)

    })
    
    let today = new Date();
    let setToday = today.getFullYear() +"-"+ ('0' + (today.getMonth()+1)).slice(-2) +"-"+('0' + today.getDate()).slice(-2);
    const selectedDate = ref(setToday);

    const loading = ref(true);
    const scheduleData = ref([]);
    const toolData = ref([]);
    const materialData = ref([]);
    const workerData = ref([]);
    const vehicleData = ref([]);
    const editData = ref({}); 
    const schedule_id = ref();
    
    const scheduleJobData  = ref(null);  
    const myModal = ref(false);
    const printModal = ref(false);
    const printVehiclePlatNo = ref(null);    
    const new_material_quantity = ref(null);
    const new_material = ref(null);
    const new_tool_quantity = ref(null);
    const new_tool = ref(null);
    
    const editTools = ref([]);
    const editMaterials = ref([]);
    const editWorkers = ref([]);
    const editTasks = ref([]);
    const editRemarks = ref(null);

     store.dispatch('app-vehicle/fetchVehicles')
      .then(response => {
        vehicleData.value = response.data;  
      })
      .catch(error => {
        if (error.response.status === 404) {
          vehicleData.value = undefined
        }
      })  

     store.dispatch('app-tool/fetchTools')
      .then(response => {
        toolData.value = response.data; 
      })
      .catch(error => {
        if (error.response.status === 404) {
          toolData.value = undefined
        }
      }) 
       
     store.dispatch('app-material/fetchMaterials')
      .then(response => {
        materialData.value = response.data; 
      })
      .catch(error => {
        if (error.response.status === 404) {
          materialData.value = undefined
        }
      }) 

     store.dispatch('app-user/fetchUsers')
      .then(response => {
        workerData.value = response.data.filter(x => x.user_role=='User'); 
      })
      .catch(error => {
        if (error.response.status === 404) {
          workerData.value = undefined
        }
      })  

    const groupBy = (array, key) => {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {});
    };
    const sortObject = (o) => {
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    };

    const showPrintModal = () => {
      printVehiclePlatNo.value = "";
      printModal.value = true;
    };
    const printListByCar = (value) => {
      
      printVehiclePlatNo.value = value.vehicle_plat_no;

      
    };
    const showModal = (subitem) => { 
      editMaterials.value = subitem.materials;
      editTools.value = subitem.tools;
      editWorkers.value = subitem.workers;
      editRemarks.value = subitem.schedule_job_remark;
      editTasks.value = subitem.tasks;
      editData.value = subitem; 
      myModal.value = true;
    };
    const getVehicleStatus = (vehicleNo) => {
      var vehicle =vehicleData.value.filter(x=>x.vehicle_plat_no == vehicleNo);
      return (vehicle.length >0)?vehicle[0].vehicle_status:'';
    };
    const loadData = () => { 
      loading.value= true;
      scheduleData.value = [];
      
      store.dispatch('app-schedule/fetchScheduleByDate', { date: selectedDate.value })
      .then(response => { 
        schedule_id.value = response.data.schedule_id;
        scheduleData.value = response.data;
        store.dispatch('app-schedule/fetchScheduleJobWithDetail', { id: response.data.schedule_id })
      .then((response) => {
        for(let z=0;z<response.data.length;z++)
        { 
            store.dispatch('app-schedule/fetchScheduleJobMaterialsWithDetails',{id:response.data[z].schedule_job_id})
            .then(subresponse => { 
              response.data[z]["materials"] = subresponse.data;
            })
            .catch(error => {
              if (error.response.status === 404) {
              }
            }); 
            store.dispatch('app-schedule/fetchScheduleJobToolsWithDetails',{id:response.data[z].schedule_job_id})
            .then(subresponse => {  
              response.data[z]["tools"] = subresponse.data;
            })
            .catch(error => {
              if (error.response.status === 404) {
              }
            });  
            store.dispatch('app-schedule/fetchScheduleJobWorkersWithDetails',{id:response.data[z].schedule_job_id})
            .then(subresponse => { 
              response.data[z]["workers"] = subresponse.data;
            })
            .catch(error => {
              if (error.response.status === 404) {
              }
            }); 
            
            store.dispatch('app-job/fetchJobTask',{id:response.data[z].job_id})
            .then(subresponse => { 
              response.data[z]["tasks"] = subresponse.data;
            })
            .catch(error => {
              if (error.response.status === 404) {
              }
            }); 
            if(response.data[z].job_status != 'Active')
            {
              console.log(response.data[z],"response.data[z]")
              response.data[z].vehicle_plat_no = "Z_Unassigned Job";
            }
        }
        for(let i=0;i<vehicleData.value.length;i++)
        {
          var checkExist = response.data.filter(function(elem){
              if(elem.vehicle_plat_no == vehicleData.value[i].vehicle_plat_no) return elem;
          });
          
          if(checkExist.length == 0)
          {
              var object = {vehicle_plat_no:vehicleData.value[i].vehicle_plat_no} 
              response.data.push(object); 
          }
        }

        // add non working list
        var object2 = {vehicle_plat_no:'Z_Unassigned Job'} 
        response.data.push(object2); 
        scheduleJobData.value = groupBy(response.data,'vehicle_plat_no');
        scheduleJobData.value = sortObject(scheduleJobData.value);
        loading.value=false;
        
      }) .catch(error => {
        if (error.response.status === 404) {
          scheduleData.value = undefined
          
        }
        
      }) 
      
      })
      .catch(error => {
        if (error.response.status === 404) {
          scheduleData.value = undefined
        }
        loading.value=false;
      }) 
    
    };
    const hideModal = () => {
      myModal.value = false; 
    };
    const addToRemark = (lineItem) =>{
      if(editRemarks.value == null)
      {
        editRemarks.value = lineItem + String.fromCharCode(13, 10);
      }
      else
      {
        if(editRemarks.value.length > 0)
        {
          editRemarks.value = editRemarks.value  + String.fromCharCode(13, 10);
        }
        editRemarks.value = editRemarks.value + lineItem + String.fromCharCode(13, 10);
      }
    };
    const removeAllMaterialItem= () => {      
      editMaterials.value = [];
    };
    const removeMaterialItem = (item) => {      
      editMaterials.value.splice(editMaterials.value.indexOf(item),1); 
    };
    const removeAllToolItem = (item) => {      
      editTools.value = [];
    };
    const removeToolItem = (item) => {      
      editTools.value.splice(editTools.value.indexOf(item),1); 
    };
    const addMaterialItem = () => {
      
      var material = {
        material_id:new_material.value.material_id,
        material_name:new_material.value.material_name,
        material_quantity:new_material_quantity.value,
        sjm_quantity:new_material_quantity.value
      };
     
      editMaterials.value.push(material);
      new_material.value = {};
      new_material_quantity.value = ''; 
    };
    const addToolItem = () => { 
      var tool = {
        tool_id:new_tool.value.tool_id,
        tool_name:new_tool.value.tool_name,
        tool_quantity:new_tool_quantity.value,
        sjt_quantity:new_tool_quantity.value
      };
      
      editTools.value.push(tool);
      new_tool.value = {};
      new_tool_quantity.value = ''; 
    };
     
    const doUpdateWorkers = (value) => {
      editWorkers.value = value;
    };
    const saveScheduleJob = (item) =>{
      item.tools = editTools.value;
      item.materials = editMaterials.value;
      item.workers = editWorkers.value;
      item.schedule_job_remark= editRemarks.value;
      myModal.value = false;
    };
    const doSave = () => {
      loading.value = true; 
    store.dispatch('app-schedule/updateSchedule', scheduleData.value )
      .then(response => {
        store.dispatch('app-schedule/updateScheduleJobs', { id: schedule_id.value, schedules: JSON.stringify(scheduleJobData.value) })
          .then(response => { 
            location.reload();
          })
          .catch(error => {
            if (error.response.status === 404) {
              scheduleData.value = undefined
            }
          }) 
      })
      .catch(error => {
        if (error.response.status === 404) {
          scheduleData.value = undefined
        }
      }) 
    };
    onMounted(() => {  
      loadData(); 
    }); 

    return { 
      scheduleJobData,
      scheduleData,
      myModal,
      printVehiclePlatNo,
      showPrintModal,
      printListByCar,
      showModal,
      hideModal,
      doSave,
      addToRemark,
      removeMaterialItem,
      removeAllMaterialItem,
      addMaterialItem,
      addToolItem,
      removeToolItem,
      removeAllToolItem,
      doUpdateWorkers,
      saveScheduleJob,
      loadData,
      getVehicleStatus,
      materialData,
      toolData,
      vehicleData,
      workerData,
      editData,
      editTools,
      editMaterials,
      editWorkers,
      editRemarks,
      editTasks,
      new_material,
      new_material_quantity,
      new_tool,
      new_tool_quantity,
      printModal,
      selectedDate,
      schedule_id,
      loading
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
