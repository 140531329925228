<template> 
  <div style="text-align:center;background:white;opacity:0.7;width:100%;height:100%;position:fixed;z-index:9999;top:0;left:0;"> 
    <b-spinner label="Loading..." />
      <b-card-text> 
      <span>loading...</span>
    </b-card-text> 
    </div>
</template>

<script> 
import { BSpinner, BCardText } from 'bootstrap-vue' 

export default {
  components: { 
    BSpinner,
    BCardText,
  },
  data() {
    return { 
    }
  },
}
</script>
